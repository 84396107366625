<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[试题解答]在线试题解答/居家办公，4-7千/月</p>
    <div>
      <div class="title">● 招募岗位</div>
      <p class="psd">试题解答</p>
    </div>
    <div>
      <div class="title">● 工作内容</div>
      <p class="psd">
        线上录制相应学科的题目讲题视频，题目简单，提供参考答案和解析。
      </p>
    </div>
    <div>
      <div class="title">● 岗位要求</div>
      <p class="psd">
        线上办公，在家办公，可以异地办公，录制不需露脸，时间地点自由，任务量充足，录制会有教程。
      </p>
    </div>
    <div>
      <div class="title">● 福利待遇</div>
      <p class="psd">时间地点不限，月薪4-7000元</p>
    </div>
    <div>
      <div class="title">【报名方式】</div>
      <img src="@/assets/images/stjd.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="info">请扫码添加企业微信报名！</div>
      <p class="psd">
        【岗位仅限本科及以上学历报名，所有报名均免费，每日报名人数较多，报名后请勿删除，避免收不到录取信息】
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg span {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
  .info {
    font-weight: bolder;
    text-align: center;
    margin-bottom: 10px;
  }
}
</style>
